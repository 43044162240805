<template>
    <div>
    <b-form>
            <b-row>
                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                    Church Info
                    </h5>
                    <small class="text-muted">Enter Church Info </small>
                </b-col>
                <b-col md="3">
                    <validation-provider
                    #default="{ errors }"
                    name="Membership Type"
                    rules="required"
                    >
                    <b-form-group
                        label="Membership Type" 
                        label-for="memberDataship"
                        :state="errors.length > 0 ? false:null"
                    >
                        <v-select
                        :reduce="option => option.value"
                        v-model="memberData.membershipType"
                        :options="membershipOptions"
                        :selectable="option => ! option.value.includes('select_value')"
                        label="text"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col md="3">
                  <b-form-group
                      label="Membership ID"
                      label-for="membership-id"
                      >

                          <b-form-input
                          id="membership-id"
                          v-model="memberData.memID"
                          placeholder="membership ID"
                          />
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                    label="Date Joined"
                    label-for="datejoined"
                    >
                    <flat-pickr
                        v-model="memberData.dateJoined"
                        class="form-control"
                        :config="{ dateFormat: 'Y-m-d'}"
                        placeholder="YYYY-MM-DD"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group
                    label="Date Died"
                    label-for="date-died"
                  >
                  <flat-pickr
                      v-model="memberData.dateDied"
                      class="form-control"
                      :config="{ dateFormat: 'Y-m-d'}"
                      placeholder="YYYY-MM-DD"
                      :disabled="memberData.membershipType !== 'deceased'"
                      />
                  </b-form-group>
                </b-col>
                
                <b-col md="3">
                    <b-form-group
                    label="Tithe Payer"
                    label-for="tithe"
                    >
                    <b-form-radio-group
                        id="tithe"
                        v-model="memberData.tithePayer"
                        :options="booleanOptions"
                        
                    />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group
                        label="Groups" 
                        label-for="groups"
                    >
                        <v-select
                        v-model="memberData.groups"
                        :options="getAllGroups.map(g=>({ group: g.group, church: g.church }))"
                        label="group"
                        multiple
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group
                      label="Short Bio"
                      label-for="short-id"
                      >

                          <b-form-textarea
                          type="textarea"
                          id="short-id"
                          v-model="memberData.bio"
                          placeholder="Short Bio"
                          ></b-form-textarea>
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group
                      label="Other "
                      label-for="other-info"
                      >

                          <b-form-textarea
                          id="other-info"
                          v-model="memberData.other"
                          placeholder="Other Information"
                          ></b-form-textarea>
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
                </b-col>

              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Church Appointments <b-badge variant="primary" @click="addAppoint">Add</b-badge>
                </h5>
                <small class="text-muted">Enter Church appointment </small>
              </b-col>
              
              <b-col sm="12">
                <b-row v-for="(appoint, index) in memberData.churchAppointments" :key="index">

                  <b-col md="3">
                      <b-form-group
                          label="Appointment" 
                          label-for="appointment"
                      >
                          <v-select
                          :reduce="option => option.appointment"
                          v-model="appoint.appointment"
                          :options="getAllAppointments.map(a=>({ appointment: a.appointment, church: a.church }))"
                          label="appointment"
                          />
                      </b-form-group>
                  </b-col>

                  <b-col md="2">
                      <b-form-group
                      label="From"
                      label-for="from"
                      >
                      <flat-pickr
                          v-model="appoint.from"
                          class="form-control"
                          :config="{ dateFormat: 'Y-m-d'}"
                          placeholder="YYYY-MM-DD"
                          />
                      </b-form-group>
                  </b-col>

                  <b-col md="2">
                      <b-form-group
                      label="To"
                      label-for="to"
                      >
                      <flat-pickr
                          v-model="appoint.to"
                          class="form-control"
                          :config="{ dateFormat: 'Y-m-d'}"
                          placeholder="YYYY-MM-DD"
                          />
                      </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group
                        label="Remarks "
                        label-for="remarks"
                        >

                            <b-form-textarea
                            id="other-info"
                            v-model="appoint.remarks"
                            placeholder="Appointment Remarks"
                            ></b-form-textarea>
                            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        </b-form-group>
                  </b-col>

                  <b-col md="1">
                      <b-form-group
                      label="Add"
                      label-for=""
                      >
                      <b-button
                        variant="primary"
                        @click="addAppoint"
                        >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                          class="mr-0 mr-sm-10"
                        />
                      </b-button>
                      </b-form-group>
                  </b-col>
                  <b-col md="1">
                      <b-form-group
                      label="Remove"
                      label-for=""
                      >
                      <b-button
                        variant="danger"
                        @click="delAppoint(index)"
                        >
                        <feather-icon
                          icon="XIcon"
                          size="16"
                        />
                      </b-button>
                      </b-form-group>
                  </b-col>

                  </b-row>
                </b-col>  
                <!-- </b-row>
                <b-row> -->
                <b-col
                    cols="12"
                    class="my-2"
                >
                    <h5 class="mb-0">
                    Confirmation & Baptism Info
                    </h5>
                    <small class="text-muted">Enter Confirmation & Baptism Info </small>
                </b-col>
                
                <b-col md="3">
                    <b-form-group
                    label="Communicant (The Lord's supper)"
                    label-for="communicant"
                    >
                    <b-form-radio-group
                        id="communicant"
                        v-model="memberData.communicant"
                        :options="booleanOptions"
                        
                    />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group
                    label="Confirmation Date"
                    >
                    <flat-pickr
                    :disabled="!memberData.communicant"
                        v-model="memberData.confirmationDate"
                        class="form-control"
                        :config="{ dateFormat: 'Y-m-d'}"
                        placeholder="YYYY-MM-DD"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group
                    label="Baptism By"
                    label-for="baptismby"
                    >
                        <b-form-input
                        id="baptismby"
                        :disabled="!memberData.communicant"
                        v-model="memberData.baptismBy"
                        placeholder="baptism by"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group
                    label="Baptism Date"
                    >
                    <flat-pickr
                    :disabled="!memberData.communicant"
                        v-model="memberData.baptismDate"
                        class="form-control"
                        :config="{ dateFormat: 'Y-m-d'}"
                        placeholder="YYYY-MM-DD"
                        />
                    </b-form-group>
                </b-col>

                </b-row>

        <b-col class="mt-2">
            <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submitForm"
            >
            Save Changes
            </b-button>
            <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
            Reset
            </b-button>
        </b-col>
    </b-form>
    </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback, BFormRadioGroup, BFormCheckboxGroup, BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useGroupsList from '../../groups/group-list/useGroupList'
import useAppointmentList from '../../appointment/appointment-list/useAppointmentList'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea , BButton, BInputGroup, BInputGroupPrepend, vSelect, flatPickr, BFormInvalidFeedback, BFormRadioGroup, BFormCheckboxGroup, ValidationProvider, ValidationObserver, BBadge
  },
  props: {
    memberData: {
      type: Object,
      required: true
    }
  },
  setup(props) {

    // Use toast
    const toast = useToast()

    const memberDataSocial = {
      twitter: 'https://www.twitter.com/adoptionism744',
      facebook: 'https://www.facebook.com/adoptionism664',
      instagram: 'https://www.instagram.com/adopt-ionism744',
      github: 'https://www.github.com/madop818',
      codepen: 'https://www.codepen.com/adoptism243',
      slack: '@adoptionism744',
    }

    // ? dataField is field name of `memberDataSocial` object
    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'instagram',
      },
      {
        icon: 'GithubIcon',
        dataField: 'github',
        label: 'github',
      },
      {
        icon: 'CodepenIcon',
        dataField: 'codepen',
        label: 'codepen',
      },
      {
        icon: 'SlackIcon',
        dataField: 'slack',
        label: 'slack',
      },
    ]


    const { fetchGroups, getAllGroups } = useGroupsList({}, {}, {})

    const { fetchAppointments, getAllAppointments } = useAppointmentList({}, {}, {})

    fetchAppointments()

    fetchGroups()

    const addAppoint = () => {
      let appoint = {
        appointment: '',
        from: '',
        to: '',
        remarks: ''
      }

      if (typeof(props.memberData.churchAppointments) == "undefined") {

        props.memberData["churchAppointments"] = []
        props.memberData.churchAppointments.push(appoint)

      } else {
        props.memberData.churchAppointments.push(appoint)
      }

    }

    const delAppoint = (key) => {
      props.memberData.churchAppointments.splice(key, 1)
    }

    const booleanOptions = [
          { value: true, text: 'Yes' },
          { value: false, text: 'No' }
        ]

    const membershipOptions = [
          { value: 'select_value', text: 'Select Value' },
          { value: 'not active', text: 'Not Active' },
          { value: 'active', text: 'Active' },
          { value: 'invalid', text: 'Invalid' },
          { value: 'distant', text: 'Distant' },
          { value: 'deceased', text: 'Deceased' },
        ]

    const submitForm = () => {
      store.dispatch('app-member/updateMemberChurch', { id: router.currentRoute.params.id, memberData: {
        membershipType: props.memberData.membershipType,
        memID: props.memberData.memID,
        dateJoined: props.memberData.dateJoined,
        dateDied: props.memberData.dateDied,
        tithePayer: props.memberData.tithePayer ? props.memberData.tithePayer : null,
        communicant: props.memberData.communicant ? props.memberData.communicant : null,
        confirmationDate: props.memberData.confirmationDate,
        baptismBy: props.memberData.baptismBy,
        baptismDate: props.memberData.baptismDate,
        fullName: props.memberData.fullName,
        groups: props.memberData.groups,
        churchAppointments: props.memberData.churchAppointments,
        bio: props.memberData.bio,
        other: props.memberData.other,
      } })
      .then(response => { 
        
        // memberData.value = response.data 

        //
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      
      })
      .catch(error => {

        console.log(error)

        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    return {
      submitForm,
      memberDataSocial,
      socialInputs,
      booleanOptions,
      membershipOptions,
      getAllGroups,
      getAllAppointments,
      addAppoint,
      delAppoint
    }
  },
}
</script>

<style>

</style>
