<template>
  
  <div>
    <b-form>
      <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Marital Info
                </h5>
                <small class="text-muted">Complete Your Marital Info.</small>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Marital Status"
                  rules="required"
                >
                  <b-form-group
                    label="Marital Status" 
                    label-for="marital"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="memberData.maritalStatus"
                      :options="maritalOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      :reduce="option => option.value"
                      label="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Name Of Spouse"
                  label-for="nameofspouse"
                >
                    <b-form-input
                     :disabled="memberData.maritalStatus == 'single'"
                      id="nameOfSpouse"
                      v-model="memberData.nameOfSpouse"
                      placeholder="name of spouse"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Date Of Marriage"
                  label-for="dateofmarriage"
                >
                  <flat-pickr
                    v-model="memberData.dateOfMarriage"
                    :disabled="memberData.maritalStatus == 'single'"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Other Marital Info
                </h5>
                <small class="text-muted">Complete Your Other Marital Info.</small>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Place of Marriage"
                  label-for="placeofmarriage"
                >
                  <b-form-input
                    :disabled="memberData.maritalStatus == 'single'"
                    id="placeofmarriage"
                    v-model="memberData.placeOfMarriage"
                    placeholder="place of marriage"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                  <b-form-group
                    label="Type of Marriage" 
                    label-for="typeofmarital"
                  >
                    <v-select
                      :reduce="option => option.value"
                      id="typeofmarital"
                      :disabled="memberData.maritalStatus == 'single'"
                      v-model="memberData.typeOfMarriage"
                      :options="marriageOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                  </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Marital Note"
                  label-for="maritalnote"
                >
                    <b-form-input
                      id="maritalnote"
                      :disabled="memberData.maritalStatus == 'single'"
                      v-model="memberData.maritalnote"
                      placeholder="marital note"
                    />
                </b-form-group>
              </b-col>
            </b-row>

      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="submitForm"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Reset
        </b-button>
      </b-col>
    
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import { props } from 'vue-echarts'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,ValidationProvider,ValidationObserver,vSelect,BFormInvalidFeedback,flatPickr
  },
  props: {
    memberData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // const memberDataSocial = {
    //   twitter: 'https://www.twitter.com/adoptionism744',
    //   facebook: 'https://www.facebook.com/adoptionism664',
    //   instagram: 'https://www.instagram.com/adopt-ionism744',
    //   github: 'https://www.github.com/madop818',
    //   codepen: 'https://www.codepen.com/adoptism243',
    //   slack: '@adoptionism744',
    // }
    // Use toast
    const toast = useToast()

    // ? dataField is field name of `memberDataSocial` object
    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'instagram',
      },
      {
        icon: 'GithubIcon',
        dataField: 'github',
        label: 'github',
      },
      {
        icon: 'CodepenIcon',
        dataField: 'codepen',
        label: 'codepen',
      },
      {
        icon: 'SlackIcon',
        dataField: 'slack',
        label: 'slack',
      },
    ]

    const maritalOptions = [
          { value: 'select_value', text: 'Select Value' },
          { value: 'single', text: 'Single' },
          { value: 'married', text: 'Married' },
          { value: 'seperated', text: 'Seperated' },
          { value: 'divorced', text: 'Divorced' },
          { value: 'widower', text: 'Widower' },
    ]
        
    const marriageOptions = [
          { value: 'select_value', text: 'Select Value' },
          { value: 'ordinance', text: 'Ordinance' },
          { value: 'blessed', text: 'Blessed' },
          { value: 'customary', text: 'Customary' },
    ]

    const submitForm = () => {
      store.dispatch('app-member/updateMemberMarital', { id: router.currentRoute.params.id, memberData: {
        maritalStatus: props.memberData.maritalStatus,
        nameOfSpouse: props.memberData.nameOfSpouse,
        dateOfMarriage: props.memberData.dateOfMarriage,
        placeOfMarriage: props.memberData.placeOfMarriage,
        typeOfMarriage: props.memberData.typeOfMarriage ? props.memberData.typeOfMarriage : null,
        maritalnote: props.memberData.maritalnote,
        fullName: props.memberData.fullName,
      } })
      .then(response => { 
        
        // memberData.value = response.data 

        //
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      
      })
      .catch(error => {

        console.log(error)

        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    return {
      submitForm,
      socialInputs,
      maritalOptions,
      marriageOptions,
      required, 
      email
    }
  },
}
</script>

<style>

</style>
