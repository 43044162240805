<template>
  <component :is="memberData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="memberData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching member data
      </h4>
      <div class="alert-body">
        No member found with this member id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-members-list'}"
        >
          Member List
        </b-link>
        for other members.
      </div>
    </b-alert>

    <b-tabs
      v-if="memberData"
      pills
    >

      <!-- Tab: Personal -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Personal Info</span>
        </template>
        <member-edit-tab-personal
          :member-data="memberData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Address -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Address & Contacts </span>
        </template>
        <member-edit-tab-address :member-data="memberData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Marital -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Marital Info </span>
        </template>
        <member-edit-tab-marital :member-data="memberData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Church -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ShieldIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Church Info </span>
        </template>
        <member-edit-tab-church :member-data="memberData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import memberStoreModule from '../memberStoreModule'
import groupStoreModule from '../../groups/groupStoreModule'
import appointmentStoreModule from '../../appointment/appointmentStoreModule'
import MemberEditTabPersonal from './MemberEditTabPersonal.vue'
import MemberEditTabAddress from './MemberEditTabAddress.vue'
import MemberEditTabMarital from './MemberEditTabMarital.vue'
import MemberEditTabChurch from './MemberEditTabChurch.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    MemberEditTabPersonal,
    MemberEditTabAddress,
    MemberEditTabMarital,
    MemberEditTabChurch
  },
  setup() {
    const memberData = ref(null)

    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
    const GROUP_APP_STORE_MODULE_NAME = 'app-group'
    const APPOINTMENT_APP_STORE_MODULE_NAME = 'app-appointment'

    // Register module
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
    if (!store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_APP_STORE_MODULE_NAME, groupStoreModule)
    if (!store.hasModule(APPOINTMENT_APP_STORE_MODULE_NAME)) store.registerModule(APPOINTMENT_APP_STORE_MODULE_NAME, appointmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
      if (store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_APP_STORE_MODULE_NAME)
      if (store.hasModule(APPOINTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(APPOINTMENT_APP_STORE_MODULE_NAME)
    })

      store.dispatch('app-member/fetchMember', { id: router.currentRoute.params.id })
      .then(() => {
        
        // memberData.value = response.data 

        //
        const response =  store.getters['app-member/getMember']

        memberData.value = response
      
      })
      .catch(error => {
        if (error.response.status === 404) {
          memberData.value = undefined
        }
      })

    return {
      memberData,
    }
  },
}
</script>

<style>

</style>
