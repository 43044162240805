<template>
  <div>

    <!-- Header: Personal Info -->
    <!-- <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div> -->

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Address Info
          </h5>
          <small class="text-muted">Enter Your Address Info.</small>
        </b-col>
        
        <b-col md="4" sm="6">
          <b-form-group
            label="Address (P.O.Box / GPS / Address)"
            label-for="address"
          >
            <validation-provider
              #default="{ errors }"
              name="address"
              rules="required|min:2"
            >
              <b-form-input
                id="address"
                v-model="memberData.addressName"
                placeholder="address"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" sm="6">
          <b-form-group
            label="Residence (Name of Area)"
            label-for="residence"
          >
              <b-form-input
                v-model="memberData.residence"
                placeholder="residence"
              />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="6">
          <b-form-group
            label="GPS Coordinates (lat, lng)"
            label-for="coordinates"
          >
              <b-form-input
                v-model="memberData.address1"
                placeholder="gps coordinates"
              />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Nationality"
            rules="required"
          >
            <b-form-group
              label="Nationality" 
              label-for="nationality"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="memberData.nationality"
                :options="nationOptions"
                :reduce="option => option.value"
                :selectable="option => ! option.value.includes('select_value')"
                label="text"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="4" sm="6"
          >
          <b-form-group
              label="Is Father Alive"
              label-for="is-father-alive"
              label-class="mb-1"
          >
              <b-form-radio-group
              id="father"
              v-model="memberData.isFatherAlive"
              :options="booleanOptions"
              />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" sm="6"
          >
          <b-form-group
              label="Is Mother Alive"
              label-for="is-mother-alive"
              label-class="mb-1"
          >
              <b-form-radio-group
                id="mother"
                v-model="memberData.isMotherAlive"
                :options="booleanOptions"
                :selectable="option => ! option.value.includes('select_value')"
              />
          </b-form-group>
        </b-col>
        <!-- Emergency -->
        <b-col md="4" sm="6">
          <b-form-group
            label="Home Town"
            label-for="home-town"
          >
              <b-form-input
                id="home-town"
                v-model="memberData.hometown"
                placeholder="home town"
              />
          </b-form-group>
        </b-col>
        <b-col md="4">
            <b-form-group
              label="Region"
              label-for="region"
              
            >
              <v-select
                v-model="memberData.region"
                :options="regionOptions"
                label="text"
              />
            </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="District"
            label-for="district"
          >
              <b-form-input
                v-model="memberData.district"
                placeholder="District"
              />
          </b-form-group>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="my-2"
        >
          <h5 class="mb-0">
            Emergency Contact Info 
          </h5>
          <small class="text-muted">Enter Your emergency contact Info. </small> <b-badge @click="duplicateEmg" variant="primary">Copy for Next of Kin </b-badge>
        </b-col>
        <b-col md="4" sm="6">
          <b-form-group
            label="Emergency Contact Fullname"
            label-for="emg-fname"
          >
              <b-form-input
                id="emg-fname"
                v-model="memberData.emgFname"
                placeholder="Emergency Contact Fullname"
              />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-row>
              <b-col md="4">
                  <b-form-group
                      label="Country"
                      label-for="country"
                      >
                          <vue-country-code
                              class=" is-size-5"
                              @onSelect="onSelect4"
                              :enabledCountryCode="true"
                              :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                              >
                          </vue-country-code>
                  </b-form-group>
              </b-col>
              <b-col md="8">
                  <b-form-group
                      label="Emergency Contact"
                      label-for="emg-contact"
                      >

                          <b-form-input
                          id="emg-contact"
                          v-model="memberData.emgCont.tel"
                          placeholder="XXXXXXXXX"
                          type="tel"
                          />
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
              </b-col>
          </b-row>
        </b-col>
        <b-col md="4">
          
            <b-form-group
              label="Emergency Contact Relationship"
              label-for="nok-rel"
            >
              <v-select
                :reduce="option => option.value"
                v-model="memberData.emgRel"
                :options="nokRelOptions"
                :selectable="option => ! option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="my-2"
        >
          <h5 class="mb-0">
            Next of Kin Info
          </h5>
          <small class="text-muted">Enter Your Next of Kin Info.</small>
        </b-col>
        
        <b-col md="4" sm="6">
          <b-form-group
            label="Next of Kin Fullname"
            label-for="nok-fname"
          >
              <b-form-input
                id="nok-fname"
                v-model="memberData.nokFname"
                placeholder="Next of Kin Fullname"
              />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-row>
              <b-col md="4">
                  <b-form-group
                      label="Country"
                      label-for="country"
                      >
                          <vue-country-code
                              class=" is-size-5"
                              @onSelect="onSelect3"
                              :enabledCountryCode="true"
                              :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                              >
                          </vue-country-code>
                  </b-form-group>
              </b-col>
              <b-col md="8">
                  <b-form-group
                      label="Next of Kin Contact"
                      label-for="nok-contact"
                      >

                          <b-form-input
                          id="nok-contact"
                          v-model="memberData.nokCont.tel"
                          placeholder="XXXXXXXXX"
                          type="tel"
                          />
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
              </b-col>
          </b-row>
        </b-col>
        <b-col md="4">
          
            <b-form-group
              label="Next of Kin Relationship"
              label-for="nok-rel"
            >
              <v-select
                :reduce="option => option.value"
                v-model="memberData.nokRel"
                :options="nokRelOptions"
                :selectable="option => ! option.value.includes('select_value')"
                label="text"
              />
            </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submitForm"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormInvalidFeedback, BBadge
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
// import { props } from 'vue-echarts'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, ValidationProvider, ValidationObserver, BFormInvalidFeedback, BBadge
  },
  props: {
    memberData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    // Use toast
    const toast = useToast()

    const memberDataInfo = ref({
      dob: null,
      mobile: '+6595895857',
      website: 'https://rowboat.com/insititious/Angelo',
      language: 'French',
      gender: 'female',
      contactOptions: ['Email', 'Message'],
      addressLine1: 'A-65, Belvedere Streets',
      addressLine2: '',
      postcode: '',
      city: 'New York',
      state: '',
      country: '',
    })

    const booleanOptions = [
          { value: true, text: 'Yes' },
          { value: false, text: 'No' }
        ]


    const nationOptions = [
            { value: 'select_value', text: 'Select Value' },
            { value: 'ghanaian', text: 'Ghanaian' },    
            { value: 'nigerian', text: 'Nigerian' },    
            { value: 'ivorian', text: 'Ivorian' },    
            { value: 'togolese', text: 'Togolese' },    
            { value: 'burkinabe', text: 'Burkinabe' },
        ]

    const nokRelOptions = [
            { value: 'select_value', text: 'Select Value' },
            { value: 'parent', text: 'Parent' },    
            { value: 'auntie/uncle', text: 'Auntie / Uncle' },    
            { value: 'child', text: 'Child' },    
            { value: 'sibling', text: 'Sibling' },    
            { value: 'cousin', text: 'Cousin' },
            { value: 'spouse', text: 'Spouse' },
            { value: 'friend', text: 'Friend' },
            { value: 'guardian', text: 'Guardian' },
            { value: 'other', text: 'Other' },
        ]


    const regionOptions = ['select_value', 'Ahafo','Ashanti','Brong Ahafo','Bono East','Central','Eastern','Greater Accra','Northern','Upper East','Upper West','Volta','Western','Savannah','Oti','Western North','North East']

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]

    const contactOptionsOptions = ['Email', 'Message', 'Phone']

    const onSelect3 = ({name, iso2, dialCode}) => {
          props.memberData.nokCountry = "+"+dialCode
          console.log(name, iso2, dialCode);
    }

    const  onSelect4 = ({name, iso2, dialCode}) => {
          props.memberData.emgCountry = "+"+dialCode
          console.log(name, iso2, dialCode);
    }

    const duplicateEmg = () => {
      
      props.memberData.nokFname = props.memberData.emgFname
      props.memberData.nokCountry = props.memberData.emgCountry
      props.memberData.nokCont.tel = props.memberData.emgCont.tel
      props.memberData.nokRel = props.memberData.emgRel
    }

    const submitForm = () => {
      store.dispatch('app-member/updateMemberAddress', { id: router.currentRoute.params.id, memberData: {
        address: props.memberData.address ? props.memberData.address : '',
        residence: props.memberData.residence,
        addressName: props.memberData.addressName,
        nationality: props.memberData.nationality,
        isFatherAlive: props.memberData.isFatherAlive ? props.memberData.isFatherAlive : null,
        isMotherAlive: props.memberData.isMotherAlive ? props.memberData.isMotherAlive : null,
        hometown: props.memberData.hometown,
        region: props.memberData.region,
        district: props.memberData.district,
        emgFname: props.memberData.emgFname,
        emgCountry: props.memberData.emgCountry,
        emgCont: props.memberData.emgCont.tel,
        emgRel: props.memberData.emgRel,
        nokFname: props.memberData.nokFname,
        nokCountry: props.memberData.nokCountry,
        nokCont: props.memberData.nokCont.tel,
        nokRel: props.memberData.nokRel,
        fullName: props.memberData.fullName,
      } })
      .then(response => { 
        
        // memberData.value = response.data 

        //
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      
      })
      .catch(error => {

        console.log(error)

        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    return {
      submitForm,
      memberDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
      booleanOptions,
      nationOptions,
      regionOptions,
      nokRelOptions,
      onSelect3,
      onSelect4,
      duplicateEmg
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
