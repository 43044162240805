<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="memberData.avatar"
          :text="avatarText(memberData.fullName)"
          :variant="`light-${resolveMemberRoleVariant(memberData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ memberData.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form class="mb-2">

      <validation-observer
          ref="accountRules"
          tag="form"
        >
      
        <b-row>

        <!-- Field: Username -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="memberData.username"
            />
          </b-form-group>
        </b-col> -->
      
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="memberData.fullName"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" sm="6"
          >
          <b-form-group
              label="Gender"
              label-for="gender"
              label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="memberData.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="memberData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- contact 1 -->
        <b-col md="4"  sm="6">
                <b-row>
                    <b-col md="4" sm="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8" sm="8">
                        <b-form-group
                            label="Contact 1"
                            label-for="contact1"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact1"
                                    rules="required|min:9"
                                >
                                    <b-form-input
                                    id="contact1"
                                    v-model="memberData._contact1"
                                    placeholder="XXXXXXXXX"
                                    type="tel"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                    </b-col>
                </b-row>
              </b-col>
          
          <!-- contact 2 -->
              <b-col md="4"  sm="6">
                <b-row>
                    <b-col md="4" sm="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect2"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8" sm="8">
                        <b-form-group
                            label="Contact 2"
                            label-for="contact2"
                            >

                                <b-form-input
                                id="contact2"
                                v-model="memberData._contact2"
                                placeholder="XXXXXXXXX"
                                type="tel"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                            </b-form-group>
                    </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" sm="6" lg="4"
                >
                <b-form-group
                    label="Contact Options"
                    label-for="contact-options"
                    label-class="mb-1"
                >
                    <b-form-checkbox-group
                    id="contact-options"
                    v-model="memberData.contactOptions"
                    :options="contactOptionsOptions"
                    />
                </b-form-group>
              </b-col>

              <b-col ols="12" sm="6" lg="4"
                >
                <b-form-group
                    label="Language Options"
                    label-for="language-options"
                    label-class="mb-1"
                >
                    <b-form-checkbox-group
                    id="language-options"
                    v-model="memberData.languages"
                    :options="languageOptions"
                    />
                </b-form-group>
              </b-col>

      </b-row>
      
      <b-row>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Birth Details
                </h5>
                <small class="text-muted">
                  Enter Your Birth Details.
                </small>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Birth Date"
                    label-for="birth-date"
                >
                    <flat-pickr
                    v-model="memberData.dob"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Birth Place"
                  label-for="birth-place"
                >
                    <b-form-input
                      v-model="memberData.pob"
                      type="text"
                      placeholder="place of birth"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                  <b-form-group
                    label="Birth Day (Dayborn)"
                    label-for="dayborn"
                    
                  >
                    <v-select
                      id="dayborn"
                      v-model="memberData.dayborn"
                      :reduce="option => option.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="daybornOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                  </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Work Details
                </h5>
                <small class="text-muted">
                  Enter Your Work Details.
                </small>
              </b-col>
              
              <b-col cols="12" md="4" sm="6"
                >
                <b-form-group
                    label="Work Status"
                    label-for="work-status"
                    label-class="mb-1"
                >
                    <b-form-radio-group
                    id="workstatus"
                    v-model="memberData.workStatus"
                    :options="workStatusOptions"
                    value="male"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Occupation"
                    label-for="occupation"
                >
                    <b-form-input
                     :disabled="memberData.workStatus == 'Unemployed'"
                      v-model="memberData.occupation"
                      type="text"
                      placeholder="occupation"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Work Place"
                    label-for="place-of-work"
                >
                    <b-form-input
                     :disabled="memberData.workStatus == 'Unemployed'"
                      v-model="memberData.pow"
                      type="text"
                      placeholder="place of work"
                    />
                </b-form-group>
              </b-col>
              
            </b-row>
      </validation-observer>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="submitForm"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormRadioGroup,
    BFormCheckboxGroup, 
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { ref } from '@vue/composition-api'
import useMemberList from '../members-list/useMemberList'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    flatPickr
  },
  props: {
    memberData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const accountRules = ref(null)

    // Use toast
    const toast = useToast()

    const { resolveMemberRoleVariant } = useMemberList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const genderOptions = [
      { value: 'male', text: 'Male' },
      { value: 'female', text: 'Female' }
    ]
    
    const daybornOptions = [
      { value: 'select_value', text: 'Select Value' },
      { value: 'sunday', text: 'Sunday' },
      { value: 'monday', text: 'Monday' },
      { value: 'tuesday', text: 'Tuesday' },
      { value: 'wednesday', text: 'Wednesday' },
      { value: 'thursday', text: 'Thursday' },
      { value: 'friday', text: 'Friday' },
      { value: 'saturday', text: 'Saturday' },
    ]

    const languageOptions = ['English', 'Twi', 'Ga', 'Dangme', 'Ewe', 'Hausa']

    const workStatusOptions = ['Employed', 'Unemployed', 'Self-employed', 'Retired' ]

    const  contactOptionsOptions = ['Email', 'Message', 'Phone']

    const onSelect = ({name, iso2, dialCode}) => {
          props.memberData.country = "+"+dialCode
          console.log(name, iso2, dialCode);
    }

    const onSelect2 = ({name, iso2, dialCode}) => {
          props.memberData.country1 = "+"+dialCode
          console.log(name, iso2, dialCode);
    }

    const validationForm = ($refs) => {
        return new Promise((resolve, reject) => {
          $refs.accountRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }


    const submitForm = () => {
      store.dispatch('app-member/updateMemberPersonal', { id: router.currentRoute.params.id, memberData: {
        avatar: props.memberData.avatar,
        fullName: props.memberData.fullName,
        gender: props.memberData.gender,
        country: props.memberData.country,
        country1: props.memberData.country1,
        contact1: props.memberData._contact1,
        contact2: props.memberData._contact2,
        email: props.memberData.email,
        dob: props.memberData.dob,
        pob: props.memberData.pob,
        dayborn: props.memberData.dayborn,
        pow: props.memberData.pow,
        occupation: props.memberData.occupation,
        workStatus: props.memberData.workStatus,
        contactOptions: props.memberData.contactOptions,
        languages: props.memberData.languages,

      } })
      .then(response => { 
        
        // memberData.value = response.data 

        //
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      
      })
      .catch(error => {

        console.log(error)

        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.memberData.avatar = base64
    })

    return {
      resolveMemberRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,
      genderOptions,
      daybornOptions,
      languageOptions,
      workStatusOptions,
      contactOptionsOptions,
      onSelect,
      onSelect2,
      validationForm,
      submitForm,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      accountRules,

      required, 
      email
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vue-country-select  {
  width: 100%
}

.vue-country-select > .dropdown {
   padding: 0.6em !important;
}
</style>
